// @flow
import type { LinkResolver } from '../types';

const localizeLinkResolver = require('./localizeLinkResolver');

function wrapLinkResolver(linkResolver: LinkResolver): LinkResolver {
  const localizedLinkResolver = localizeLinkResolver(linkResolver);
  return opts => doc => {
    // IMPORTANT: additional notes for FlowFixMe located in this function:
    // the LinkResolver type is defined so the link is resolved, hence a
    // string must be returned. This is the behavior we want in the rest of
    // the app, because we are handling the edge cases in this very function.
    // This is why it is easier and makes sense to ignore the cases here than
    // relax or parametrize the type
    if (!doc.type) {
      // $FlowFixMe --> Reason: empty document field, no link can be resolved
      return null;
    }
    return localizedLinkResolver(opts)(doc);
  };
}

module.exports = wrapLinkResolver;
