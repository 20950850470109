// @flow

import type { LinkResolver } from '../types';

const find = require('lodash/find');

const { i18n } = require('../../i18n');

function localizeLinkResolver(linkResolver: LinkResolver): LinkResolver {
  return opts => doc => {
    const locale = find(i18n.getLocales(), { prismicLocale: doc.lang });

    const localizePath = (path: string) =>
      locale ? i18n.localizePath(locale, path) : path;

    return localizePath(linkResolver({ ...opts, locale })(doc));
  };
}

module.exports = localizeLinkResolver;
