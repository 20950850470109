// @flow
import type { HtmlSerializer, LinkResolver } from '../types';

const React = require('react');
const { renderToStaticMarkup } = require('react-dom/server');
const identity = require('lodash/identity');

export type Options = {|
  linkResolver: LinkResolver,
  wrapElement?: React.Node => React.Node,
|};

function wrapHtmlSerializer(
  htmlSerializer: HtmlSerializer,
  { linkResolver, wrapElement = identity }: Options,
): HtmlSerializer {
  return opts => (...args) => {
    const html = htmlSerializer({
      ...opts,
      linkResolver,
    })(...args);

    return html && typeof html === 'object'
      ? renderToStaticMarkup(wrapElement(html))
      : html;
  };
}

module.exports = wrapHtmlSerializer;
