// @flow
import * as React from 'react';
import type { Theme } from '@material-ui/core/styles';
import ThemeProvider from '@material-ui/styles/ThemeProvider';

// eslint-disable-next-line react/display-name
const wrapElementWithThemeProvider = (theme: Theme) => (
  element: React.Node,
) => <ThemeProvider theme={theme}>{element}</ThemeProvider>;

export default wrapElementWithThemeProvider;
