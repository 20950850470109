// @flow
import type { PreviewPathResolver } from '../../src/plugins/prismic/types';
import type { PreviewPathResolvers, PreviewPathResolverByType } from './types';

/* GatsbyStarterConfig: preview paths for Prismic nodes */
const previewPathResolvers: PreviewPathResolvers = {
  '404_page': () => '/404',
  blog_entry: undefined,
  news_entry: undefined,
  news_entry_archive: undefined,
  press_entry: undefined,
  press_entry_archive: undefined,
  contact_page: undefined,
  contact_form: () => '/contact',
  cookie_consent: undefined,
  home_page: () => '/',
  sector_page: undefined,
  product_page: undefined,
  partner_page: undefined,
  solution_page: undefined,
  know_us_page: undefined,
  simple_page: undefined,
  complex_page: undefined,
  use_case: undefined,
  use_case_archive: undefined,
  white_paper: () => '/descubrenos',
  white_paper_archive: undefined,
  white_paper_form: () => '/descubrenos',
  organization: undefined,
  author: undefined,
  site_info: undefined,
  menu: undefined,
  newsletter_form: () => '/',
  redirect: undefined,
  entry_tag: undefined,
  resellers_page: undefined,
  reseller: undefined,
  reseller_form: undefined,
  country: undefined,
};

const defaultPreviewPathResolver: PreviewPathResolverByType = ({
  previewPath,
  doc,
}) => `${previewPath}/${doc.type}`;

const previewPathResolver: PreviewPathResolver = ({
  locale,
  previewPath,
}) => doc => {
  const resolvePath: PreviewPathResolverByType =
    previewPathResolvers[doc.type] || defaultPreviewPathResolver;

  return resolvePath({ locale, previewPath, doc });
};

module.exports = previewPathResolver;
