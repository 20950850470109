// @flow
import type { Theme } from '@material-ui/core/styles';

import type { Config } from '../types';

const wrapLinkResolver = require('./wrapLinkResolver');
const wrapHtmlSerializer = require('./wrapHtmlSerializer');
const wrapElementWithThemeProvider = require('../../material-ui/helpers/wrapElementWithThemeProvider')
  .default;

export type ConfigOptions = {
  ...$Exact<Config>,
  theme: Theme,
};

const wrapConfig = ({ theme, ...config }: ConfigOptions): Config => {
  const linkResolver = wrapLinkResolver(config.linkResolver);
  const htmlSerializer = wrapHtmlSerializer(config.htmlSerializer, {
    linkResolver,
    wrapElement: wrapElementWithThemeProvider(theme),
  });
  return {
    lang: '*',
    ...config,
    linkResolver,
    htmlSerializer,
  };
};

module.exports = wrapConfig;
