// @flow
import * as React from 'react';
import useTheme from '@material-ui/core/styles/useTheme';
import type { Theme } from '@material-ui/core/styles';
import { usePrismicPreview } from 'gatsby-source-prismic';

import config from '../../../../config/prismic/index';

import createPreviewPathResolver from '../helpers/createPreviewPathResolver';
import wrapConfig from '../helpers/wrapConfig';

export default function usePreviewPage<T>() {
  const { linkResolver, htmlSerializer, previewPathResolver } = wrapConfig({
    ...config,
    theme: useTheme<Theme>(),
  });

  return usePrismicPreview<T>(
    React.useMemo(
      () => ({
        repositoryName: config.repositoryName,
        accessToken: config.accessToken,
        lang: config.lang,
        linkResolver,
        htmlSerializer,
        pathResolver: createPreviewPathResolver({
          previewPathResolver,
          previewPath: config?.previewPath,
        }),
      }),
      [],
    ),
  );
}
