// @flow
/* eslint-disable global-require */
import type { Schemas } from '../types';

const schemas: Schemas = {
  '404_page': require('./404_page.json'),
  blog_entry: require('./blog_entry.json'),
  news_entry: require('./news_entry.json'),
  news_entry_archive: require('./news_entry_archive.json'),
  press_entry: require('./press_entry.json'),
  press_entry_archive: require('./press_entry_archive.json'),
  contact_page: require('./contact_page.json'),
  contact_form: require('./contact_form.json'),
  cookie_consent: require('./cookie_consent.json'),
  home_page: require('./home_page.json'),
  sector_page: require('./sector_page.json'),
  product_page: require('./product_page.json'),
  partner_page: require('./partner_page.json'),
  solution_page: require('./solution_page.json'),
  know_us_page: require('./know_us_page.json'),
  simple_page: require('./simple_page.json'),
  complex_page: require('./complex_page.json'),
  use_case: require('./use_case.json'),
  use_case_archive: require('./use_case_archive.json'),
  white_paper: require('./white_paper.json'),
  white_paper_archive: require('./white_paper_archive.json'),
  white_paper_form: require('./white_paper_form.json'),
  author: require('./author'),
  newsletter_form: require('./newsletter_form.json'),
  organization: require('./organization'),
  site_info: require('./site_info'),
  redirect: require('./redirect'),
  menu: require('./menu'),
  entry_tag: require('./entry_tag'),
  resellers_page: require('./resellers_page'),
  reseller: require('./reseller'),
  reseller_form: require('./reseller_form'),
  country: require('./country'),
};

module.exports = schemas;
