// @flow
import * as React from 'react';
import { navigate } from 'gatsby';
import withStyles, {
  type WithStyles,
} from '@plugins/material-ui/hocs/withStyles';
import type { Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';

import ResPadding from '~plugins/material-ui/components/ResPadding';
import AppHelmet from '~plugins/helmet/components/AppHelmet';
import usePreviewPage from '~plugins/prismic/hooks/usePreviewPage';
import type { Query } from '~schema';

export type ClassKey = 'root';

export type Props = {
  ...$Exact<WithStyles<ClassKey>>,
};

const styles = (unusedTheme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const Preview = ({ classes }: Props) => {
  const { previewData, path } = usePreviewPage<Query>();

  React.useEffect(() => {
    if (previewData && typeof path === 'string') {
      window.__PRISMIC_PREVIEW_DATA__ = previewData;
      navigate(path);
    }
  }, [previewData, path]);

  return (
    <Container component={ResPadding} vertical className={classes.root}>
      <AppHelmet title="Preview" />
      <CircularProgress />
    </Container>
  );
};

export default withStyles<ClassKey, *, Props>(styles, { name: 'Preview' })(Preview);
