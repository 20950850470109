// @flow
import type { HtmlSerializer } from '../types';

const React = require('react');
const Typography = require('@material-ui/core/Typography').default;
const classnames = require('classnames');
const castArray = require('lodash/castArray');

const typographyVariantByType = {
  paragraph: undefined,
  heading1: 'h1',
  heading2: 'h2',
  heading3: 'h3',
  heading4: 'h4',
  heading5: 'h5',
  heading6: 'h6',
};

// eslint-disable-next-line react/display-name
const htmlSerializer: HtmlSerializer = ({ linkResolver, ...opts }) => (
  unusedType,
  element,
  content,
  children,
  key,
) => {
  if (!element || !element.type) {
    return null;
  }

  switch (element.type) {
    case 'hyperlink':
      return React.createElement(
        'a',
        {
          ...(element.data.target
            ? { target: element.data.target, rel: 'noopener noreferrer' }
            : {}),
          key,
          href: element.data.url || linkResolver({ ...opts })(element.data),
          'data-link-type': element.data.link_type,
          title: typeof content === 'string' ? content : undefined,
        },
        children,
      );
    case 'paragraph':
    case 'heading1':
    case 'heading2':
    case 'heading3':
    case 'heading4':
    case 'heading5':
    case 'heading6':
      return React.createElement(Typography, {
        key,
        variant: typographyVariantByType[element.type],
        dangerouslySetInnerHTML: children
          ? { __html: castArray(children).join('') }
          : undefined,
      });
    case 'list-item':
    case 'o-list-item':
      return React.createElement(Typography, {
        key,
        component: 'li',
        variant: 'body1',
        dangerouslySetInnerHTML: children
          ? { __html: castArray(children).join('') }
          : undefined,
      });
    case 'group-list-item':
    case 'group-o-list-item':
      return React.createElement(
        element.type === 'group-o-list-item' ? 'ol' : 'ul',
        {
          key,
          dangerouslySetInnerHTML: children
            ? { __html: castArray(children).join('') }
            : undefined,
          className: classnames('MuiListText-root', 'MuiListText-padding', {
            'MuiListText-ordered': element.type === 'group-o-list-item',
          }),
        },
      );
    case 'preformatted':
      return React.createElement('pre', {
        key,
        dangerouslySetInnerHTML: children
          ? { __html: castArray(children).join('') }
          : undefined,
        className: classnames('MuiPreformatted-root'),
      });
    default:
      // Return null to stick with the default behavior for all other elements
      return null;
  }
};

module.exports = htmlSerializer;
