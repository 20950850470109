// @flow
import type { Config } from '../../src/plugins/prismic/types';
import type { ContentType } from './types';

const pages: $ElementType<Config, 'pages'> = [
  {
    type: ('home_page': ContentType),
    component: 'HomePage.jsx',
    getPageContext: () => ({
      appBarOnTop: true,
    }),
  },
  {
    type: ('blog_entry': ContentType),
    component: 'blog/BlogEntryPage.jsx',
    hasUid: true,
    getPageContext: () => ({
      pageType: 'article',
    }),
  },
  {
    type: ('news_entry': ContentType),
    component: 'news/NewsEntryPage.jsx',
    hasUid: true,
    getPageContext: () => ({
      pageType: 'article',
    }),
  },
  {
    type: ('news_entry_archive': ContentType),
    component: 'news/NewsEntryArchivePage.jsx',
    hasUid: true,
  },
  {
    type: ('press_entry': ContentType),
    component: 'press/PressEntryPage.jsx',
    hasUid: true,
    getPageContext: () => ({
      pageType: 'article',
    }),
  },
  {
    type: ('press_entry_archive': ContentType),
    component: 'press/PressEntryArchivePage.jsx',
    hasUid: true,
  },
  {
    type: ('sector_page': ContentType),
    component: 'SectorPage.jsx',
    hasUid: true,
    getPageContext: () => ({
      appBarOnTop: true,
    }),
  },
  {
    type: ('product_page': ContentType),
    component: 'ProductPage.jsx',
    hasUid: true,
  },
  {
    type: ('partner_page': ContentType),
    component: 'PartnerPage.jsx',
    hasUid: true,
  },
  {
    type: ('solution_page': ContentType),
    component: 'SolutionPage.jsx',
    hasUid: true,
    getPageContext: () => ({
      appBarOnTop: true,
    }),
  },
  {
    type: ('know_us_page': ContentType),
    component: 'KnowUsPage.jsx',
    hasUid: true,
    getPageContext: () => ({
      appBarOnTop: true,
    }),
  },
  {
    type: ('contact_page': ContentType),
    component: 'ContactPage.jsx',
    hasUid: true,
    getPageContext: () => ({
      appBarOnTop: true,
    }),
  },
  {
    type: ('use_case': ContentType),
    component: 'UseCasePage.jsx',
    hasUid: true,
  },
  {
    type: ('use_case_archive': ContentType),
    component: 'UseCaseArchivePage.jsx',
    hasUid: true,
  },
  {
    type: ('simple_page': ContentType),
    component: 'SimplePage.jsx',
    hasUid: true,
  },
  {
    type: ('complex_page': ContentType),
    component: 'ComplexPage.jsx',
    hasUid: true,
    dataQuery: `
      app_bar_hidden
    `,
    getPageContext: ({ data }) => ({
      appBarOnTop: true,
      appBarHidden: data?.app_bar_hidden === true,
    }),
  },
  {
    type: ('resellers_page': ContentType),
    component: 'ResellersPage.jsx',
    hasUid: true,
    getPageContext: () => ({
      appBarOnTop: true,
    }),
  },
  {
    type: ('white_paper_archive': ContentType),
    component: 'WhitePaperArchivePage.jsx',
    hasUid: true,
  },
  {
    type: ('redirect': ContentType),
    component: 'redirect/RedirectPage.jsx',
    hasUid: true,
  },
];

module.exports = pages;
